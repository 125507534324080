.container {
  margin: 0 auto;
  padding: .5rem;
}

.g-body-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.g-page {
  flex: 1;
  // background-color: var(--secondary-lighter);
  background: var(--secondary-lighter) url('/img/pipes.png') repeat 0 0 / 400px;
}

.g-page__content {
  position: relative;
  margin: 1rem auto;
  padding: 1rem;
  background-color: var(--grey-lightest);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.12),
  0 2px 4px 0 rgba(0,0,0,.08);
}

.g-page__content a {
  color: var(--accent-color);
}

.g-page__content li {
  position: relative;
  line-height: 1.35rem;

  list-style-type: none;
}

.g-page__content li:before {
  position: absolute;
  left: -25px;
  color: var(--primary);
  font-size: 1.1rem;
  font-weight: 700;
  content: '⇝';
}

.g-page__content svg {
  fill: var(--primary-darkest);
}

.g-page__aside {
  background-color: var(--primary-lightest);
}


.flex {
  display: flex;
}

.aic {
  align-items: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

@media (min-width: 768px) {
  .g-page__content {
    display: flex;
    flex-wrap: wrap;
  }

  .g-page__content:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-width: 0 100px 100px 0;
    border-style: solid;
    border-color: transparent var(--primary-lighter) transparent transparent;
    content: '';
  }

  .g-page__main {
    width: 70%;
  }

  .g-page__aside {
    width: 25%;
    margin-left: 5%;
    padding: 2rem;
  }

  .container {
    max-width: 1200px;
  }

  .w-33-l {
    width: 33%;
  }

  .flex-l {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
