.g-footer,
.g-footer__nav a {
  color: var(--white);
}

.g-footer {
  padding: 1.5rem 0;
  background-color: var(--primary-darker);
}

.g-footer .w-33-l {
  padding: 0 1rem;
}

.g-footer__nav ul {
  padding: 0;
}

.g-footer__nav li {
  line-height: 1.6;

  list-style-type: none;
}

.g-footer__nav a {
  text-decoration: none;
}
