:root {
    --black: #22292f;
    --grey-darkest: #3d4852;
    --grey-darker: #606f7b;
    --grey-dark: #8795a1;
    --grey: #b8c2cc;
    --grey-light: #dae1e7;
    --grey-lighter: #f1f5f8;
    --grey-lightest: hsla(0, 0%, 99%, 1.00);
    --white: hsla(0, 0%, 100%, 1.00);

    --primary-darkest:  hsl(180, 17%, 32%);
    --primary-darker: hsl(180, 17%, 42%);
    --primary-dark: hsl(180, 17%, 52%);
    --primary: hsl(180, 17%, 62%);
    --primary-light:  hsl(180, 17%, 72%);
    --primary-lighter:  hsl(180, 17%, 82%);
    --primary-lightest:  hsl(180, 17%, 96%);

    --secondary-darkest: hsl(92, 21%, 40%);
    --secondary-darker: hsl(92, 21%, 50%);
    --secondary-dark: hsl(92, 21%, 60%);
    --secondary: hsl(92, 21%, 73%);
    --secondary-light: hsl(92, 21%, 83%);
    --secondary-lighter: hsl(92, 21%, 90%);
    --secondary-lightest: hsl(92, 21%, 95%);

    --accent-color: #ff5722;

    --sans : 'Open Sans', sans-serif;
    --serif: 'Libre Baskerville', serif;

    --base-box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);

    @custom-media --phone-only (max-width: 320px);
    @custom-media --small-viewport (min-width: 320px);
    @custom-media --ipad-portrait (min-width: 960px);
    @custom-media --ipad-landscape (min-width: 1024px);
}
