.g-page__aside__section {
    background-color: #fff;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: var(--base-box-shadow);
    margin-bottom: 1rem;
}

.g-page__aside__section p:first-of-type {
    margin: 0;
}

.g-page__aside__section p:last-of-type {
    margin-bottom: 0;
}

.g-page__aside__section svg {
    display: block;
    margin: .5rem auto 1rem;
    fill: var(--accent-color);
}
