.home-thumbnail {
    box-shadow: var(--base-box-shadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.home-thumbnail .btn-link {
    align-self: flex-end;
    margin-top: auto;
}

.home-thumbnail h2 {
    margin-top: 0;
    font-size: 1.3em;
}

.o-page-main__header h2 {
    font-style: italic;
    margin: 0;
    position: relative;
    top: -14px;
    font-size: 1.3rem;
    left: 1rem;
}

.btn {
    text-decoration: none;
    padding: 1rem .8rem;
    color: var(--black) !important;
    font-family: var(--sans);
    display: inline-flex;
    transition: background-color .3s ease-out;
}

.btn-link {
    border: 1px solid var(--accent-color);
    border-radius: 4px;
}

.btn-link:hover {
    background-color: var(--accent-color);
    color: var(--white) !important;
}

@media (min-width: 768px) {
    .home-thumbnail {
        width: 30%;
        margin-bottom: 0;
    }
}

.info {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 2rem 1rem 2rem;
  background-color: var(--secondary-lightest);
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
}

.info svg {
  margin-right: 1rem;
}

.hr {
  margin: 3rem 0;
  padding: 0;
  border: none;
  border-top: medium double var(--primary);
  color: #333;
  text-align: center;
  overflow: visible; /* For IE */
}

.hr:after {
  display: inline-block;
  position: relative;
  top: -.7em;
  padding: 0 .25em;
  background: white;
  color: var(--secondary);
  font-size: 1.2em;
  content: '§';
}
