.g-header {
  position: sticky;
  top: 0;
  height: 80px;
  background-color: var(--primary-darker);
  z-index: 2;
}

.g-header .container {
  height: 100%;
}

.g-header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.g-header ul {
  margin: 0;
  padding: 0;
}

.g-header li {
  height: 80px;
  line-height: 80px;

  list-style-type: none;
}

.hamburger-box {
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 10px;
  width: 40px;
  height: 24px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background-color: #fff;
}

.hamburger-inner:after,
.hamburger-inner:before {
  display: block;
  content: '';
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

@media (max-width: 767px) {
  .g-header__nav:not(.active) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
    overflow: hidden;

    clip-path: inset(100%);
    clip: rect(0 0 0 0);
  }

  .g-header__nav.active ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    position: absolute;
    top: 80px;
    right: 0;
    left: 0;
    width: 100vw;
    height: 26vh;
    background-color: var(--primary-darkest);
    transition: all .2s ease-in;
  }

  .g-header__nav.active li {
    height: auto;
    line-height: 1;
  }
}



.g-header__nav:not(.active) li.active a:after,
.g-header a:after {
  display: block;
  position: absolute;
  bottom: 20px;
  height: 5px;
  transition: width .2s ease-in;
  border-radius: 2px;
  background-color: var(--secondary-lightest);
  content: '';
}

.g-header__nav li.active a:after {
  width: 85%;
}

.g-header a {
  display: block;
  position: relative;
  padding: 0 1rem;
  transition: background-color .3s ease-in;
  color: var(--white);
  font-family: var(--sans);
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.g-header a:after {
  width: 0;
}

.g-header li:hover a:after {
  width: 85%;
}

@media (min-width: 768px) {
  .hamburger-box {
    display: none;
  }

  .g-header__nav {
    display: block;
    width: 80%;
  }

  .g-header ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .logo {
    font-size: 1.5rem;
    transition: transform .2s ease-out;
  }

  .logo:hover {
    transform: translateX(.2rem);
  }

  .logo a {
    padding: 0;
  }

  .logo span {
    display: block;
    font-size: .8rem;
    font-style: italic;
    position: relative;
    top: 5px;
  }
}

@media (min-width: 1200px) {
  .g-header__nav {
    width: 60%;
  }
}
