*, *:before, *:after {
    box-sizing: border-box;
}

body {
    font-family: var(--serif);
    font-size: 16px;
    font-weight: 400;
    color: var(--black);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--sans);
    font-weight: 400;
}

h1 {
    border-left: 5px solid var(--primary);
    padding-left: 1rem;
    font-size: 1.618em;
}

h2 {
    font-size: 1.3em;
}

@media (min-width: 768px) {
    h1 {
        font-size: 2.618em;
    }

    h2 {
        font-size: 1.618em;
    }
}

p {
    line-height: 1.350;
}
